<template>
  <div class="article">
    <a-page-header title="推广详情" @back="() => $router.go(-1)" />

    <div class="actionBar">
      <a-space>
        <a-tree-select
          allowClear
          v-model="listParams.status"
          style="width: 140px"
          :tree-data="check_status"
          placeholder="状态(全部)"
        />

        <CompanySelect
          @CompanySelect="CompanySelect"
          :serchKeyword="undefined"
          :selectId="listParams.company_id"
          style="width:200px"
        ></CompanySelect>

        <a-range-picker
          style="width: 280px;"
          v-model="time"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          :locale="locale"
        >
        </a-range-picker>
        <a-button icon="search" type="primary" @click="onSearch">
          查询
        </a-button>
      </a-space>

      <a-space>
        <a-button icon="plus" type="primary" @click="showModal()">
          添加推广页面
        </a-button>
      </a-space>
    </div>

    <!-- 对话框 -->
    <a-modal
      :title="modalTitle"
      v-model="visible"
      @ok="handleOk"
      okText="确定"
      cancelText="取消"
      :width="960"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-row>
          <a-col :span="12">
            <a-form-item label="标题">
              <a-input v-model="addFromData.title" />
            </a-form-item>
            <a-form-item label="跳转地址">
              <a-input v-model="addFromData.url" />
            </a-form-item>
            <a-form-item label="简介">
              <a-input v-model="addFromData.description" />
            </a-form-item>
            <a-form-item label="推广内容">
              <a-textarea
                :maxLength="50"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                v-model="addFromData.content"
              />
            </a-form-item>
            <a-form-item label="状态">
              <a-radio-group v-model="addFromData.status">
                <a-radio :value="1">
                  启用
                </a-radio>
                <a-radio :value="0">
                  禁用
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="排序">
              <a-input-number :min="0" v-model="addFromData.list_order" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="关联企业">
              <CompanySelect
                @CompanySelect="CompanySelect2"
                :serchKeyword="addFromData.company_name"
                :selectId="addFromData.company_id"
              ></CompanySelect>
            </a-form-item>
            <a-form-item label="发布时间">
              <a-date-picker
                show-time
                v-model="addFromData.create_time"
                format="YYYY-MM-DD HH:mm:ss"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                :locale="locale"
              />
            </a-form-item>
            <a-form-item label="开始时间">
              <a-date-picker
                v-model="addFromData.start_time"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :locale="locale"
              />
            </a-form-item>
            <a-form-item label="结束时间">
              <a-date-picker
                v-model="addFromData.end_time"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :locale="locale"
              />
            </a-form-item>
            <a-form-item label="图片">
              <a-upload
                name="file"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :action="uploadImage"
                :before-upload="beforeUpload"
                @change="handleChange"
              >
                <img
                  v-if="imgUrl"
                  :src="imgUrl"
                  style="display:block;max-width:200px;max-height:200px"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>

    <a-modal v-model="PVWVisible" title="图片预览" :footer="null">
      <img
        :src="PVWImgUrl"
        style="maxWidth:100%;maxHeight:100%;margin:0 auto"
      />
    </a-modal>

    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="status" slot-scope="text">
        <a-tag :color="text == 1 ? 'green' : '#999'">
          {{ text == 1 ? "启用" : "禁用" }}
        </a-tag>
      </span>
      <span slot="image_url" slot-scope="text">
        <a v-if="text">
          <img
            @click="pvwShow(text)"
            :src="text"
            alt="图片地址发生错误"
            style="maxWidth:50px;maxHeight:50px"
          />
        </a>
        <span v-else>
          暂无
        </span>
      </span>

      <span slot="action" slot-scope="text, record">
        <FontIcon
          @click.native="editMenu(record)"
          title="修改"
          type="icon-xiugai"
          size="22"
        ></FontIcon>
        <a-divider type="vertical" />
        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  slideItemIndex,
  slideItemSave,
  slideItemDel,
  uploadImage,
} from "@/apis/index";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
const columns = [
  {
    title: "排序",
    dataIndex: "list_order",
    scopedSlots: { customRender: "list_order" },
  },
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "推广标题",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "链接",
    dataIndex: "url",
    scopedSlots: { customRender: "url" },
  },
  {
    title: "图片",
    dataIndex: "image_url",
    scopedSlots: { customRender: "image_url" },
  },
  {
    title: "发布时间",
    dataIndex: "create_time",
    scopedSlots: { customRender: "create_time" },
    customRender: (text) => {
      if (text > 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    },
  },
  {
    title: "开始时间",
    dataIndex: "start_time",
    scopedSlots: { customRender: "start_time" },
    customRender: (text) => {
      if (text > 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
  },
  {
    title: "结束时间",
    dataIndex: "end_time",
    scopedSlots: { customRender: "end_time" },
    customRender: (text) => {
      if (text > 0) {
        return moment(Number(text + "000")).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
  },
  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
const check_status = [
  {
    title: "启用",
    value: 1,
  },
  {
    title: "禁用",
    value: 0,
  },
];
export default {
  data() {
    return {
      uploadImage,
      check_status,
      locale,
      columns,
      data: [],
      modalTitle: "",
      addFromData: {},
      listParams: {
        slide_id: this.$route.query.id,
        page: 1,
        limit: 10,
        status: undefined,
        start_time: "",
        end_time: "",
        company_id: undefined,
      },
      count: 0,
      time: [],
      visible: false,
      loading: false,
      imgUrl:'',

      PVWVisible: false,
      PVWImgUrl: "",
    };
  },
  computed: {},
  watch: {
    time(a) {
      if (a) {
        this.listParams.start_time = a[0];
        this.listParams.end_time = a[1];
      } else {
        this.listParams.start_time = "";
        this.listParams.end_time = "";
      }
    },
  },
  created() {
    this.getSlideItemIndex();
  },
  methods: {
    pvwShow(text) {
      this.PVWVisible = true;
      this.PVWImgUrl = text;
    },
    CompanySelect(val) {
      this.listParams.company_id = val;
      this.getSlideItemIndex();
    },
    CompanySelect2(val) {
      this.addFromData.company_id = val;
    },
    showModal() {
      this.visible = true;
      this.modalTitle = "添加推广页面";
      this.addFromData = {
        id: "",
        slide_id: this.$route.query.id,
        title: "",
        description: "",
        url: "",
        content: "",
        status: 1,
        list_order: "",
        company_id: undefined,
        image: "",
        start_time: "",
        end_time: "",
        create_time: "",
      };
    },
    editMenu(rec) {
      this.imgUrl=rec.image_url
      this.visible = true;
      this.modalTitle = "修改推广信息";
      this.addFromData = {
        id: rec.id,
        slide_id: this.$route.query.id,
        title: rec.title,
        description: rec.description,
        url: rec.url,
        content: rec.content,
        status: rec.status,
        list_order: rec.list_order,
        company_id: rec.company_id == 0 ? undefined : rec.company_id,
        company_name: rec.company ? rec.company.company_name : undefined,
        image: rec.image,
        start_time:
          rec.start_time != 0
            ? moment(Number(rec.start_time + "000")).format("YYYY-MM-DD")
            : "",
        end_time:
          rec.end_time != 0
            ? moment(Number(rec.end_time + "000")).format("YYYY-MM-DD")
            : "",
        create_time:
          rec.create_time != 0
            ? moment(Number(rec.create_time + "000")).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "",
      };
    },
    handleOk() {
      slideItemSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.visible = false;
          this.$message.success("success");
          this.getSlideItemIndex();
        }
      });
    },
    confirm(rec) {
      slideItemDel({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getSlideItemIndex();
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getSlideItemIndex();
    },
    getSlideItemIndex() {
      slideItemIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getSlideItemIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch() {
      this.listParams.page = 1;
      this.getSlideItemIndex();
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.imgUrl=info.file.response.data.url
        this.addFromData.image = info.file.response.data.file_path;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.article {
  .actionBar {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
