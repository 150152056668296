import { render, staticRenderFns } from "./AdverMgt.vue?vue&type=template&id=ec6de194&scoped=true&"
import script from "./AdverMgt.vue?vue&type=script&lang=js&"
export * from "./AdverMgt.vue?vue&type=script&lang=js&"
import style0 from "./AdverMgt.vue?vue&type=style&index=0&id=ec6de194&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec6de194",
  null
  
)

export default component.exports